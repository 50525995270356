<template>
    <div class="no-print-sec">
        <div class="mask" v-show="isShow" @click="$emit('update:isShow', false)"></div>
        <transition name="slide">
            <div class="change-coupon-lightbox" v-show="isShow">
                <div class="side-menu-wrap">
                    <div class="side-menu">
                        <div class="side-menu-close" @click="$emit('update:isShow', false)">
                            <img src="@/assets/tgd/lightbox/close.svg" alt="" />
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item" style="background: #eee">
                                <div class="title mt-5">{{ name }}</div>
                            </div>
                        </div>
                        <div class="side-menu-top">
                            <div class="side-menu-item">
                                <div class="row">
                                    <template v-if="currentEndTime">
                                        <div class="col-6 mb-3">當班結束時間</div>
                                        <div class="col-6 mb-3 text-end">{{ currentEndTime }}</div>
                                    </template>
                                    <template v-for="item in reserveList.list">
                                        <div class="col-6 mb-3">{{ item.txt }}</div>
                                        <div class="col-6 mb-3 text-end">{{ item.time }}</div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import { reactive, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { basePost } from '@/js/services/baseService';

export default {
    name: 'RoomRightLightbox',
    props: {
        isShow: Boolean,
        id: Number,
        name: String,
        currentEndTime: String,
    },
    emits: ['update:isShow'],
    setup(props) {
        const { state } = useStore();
        const storeId = computed(() => state.auth.store_id);

        const reserveList = reactive({
            list: [],
        });

        const getReserveList = async () => {
            try {
                const res = await basePost('/api_order/renew_order_list', {
                    store_id: storeId.value,
                    member_id: props.id,
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    reserveList.list = data.res_data;
                }
            } catch (err) {
                console.error(err);
            }
        };

        // 有id就拿資料
        watch(
            () => props.id,
            (val) => {
                if (!val) return;
                getReserveList();
            }
        );
        // 關掉清除紀錄
        watch(
            () => props.isShow,
            (val) => {
                if (!val) {
                    reserveList.list = [];
                }
            }
        );

        return {
            reserveList,
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/css/mixins';
@import '~@/css/grid';
.slide-enter-active {
    transition: all ease-in 0.3s;
}

.slide-leave-active {
    transition: all ease-in 0.3s;
}

.slide-enter-from,
.slide-leave-to {
    transform: translateX(100%);
}
$side-menu-width: 350px;
.change-coupon-lightbox {
    position: fixed;
    top: 0;
    right: 0;
    width: $side-menu-width;
    min-height: 100%;
    transition: ease-in 0.4s;
    z-index: 4;
    background: #f8f8f8;
    @include phone {
        width: 100%;
    }
    .side-menu-wrap {
        overflow: overlay;
        height: 100vh;
        position: relative;
        &::-webkit-scrollbar-track {
            background-color: #d6d6d6;
            border-radius: 2px;
        }
        &::-webkit-scrollbar {
            width: 7px;
        }
        &::-webkit-scrollbar-thumb {
            background: #707070;
            box-shadow: 0px 3px 6px #00000029;
            border-radius: 9px;
        }
        .side-menu-top {
            .side-menu-item {
                padding-top: 21px;
                padding-bottom: 15px;
                padding-left: 30px;
                padding-right: 30px;
            }
        }
        .title {
            color: #000;
            font-size: 22px;
            font-weight: bold;
        }
    }
}
</style>
