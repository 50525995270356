<template>
    <div>
        <div class="mask" v-show="isShow" @click="closeLightbox"></div>
        <form
            class="wait-list-lightbox"
            v-show="isShow"
        >
            <div class="lightbox-body">
                <div class="content container table-list">
                    <div class="row header">
                        <div class="col-3">編輯</div>
                        <div class="col-2 text-center">
                            <template v-if="type === 'waiting_list'">
                                前一班<br>結束時間
                            </template>
                            <template v-else>
                                結束時間
                            </template>
                        </div>
                        <div class="col-2">狀態</div>
                        <div class="col-2">待班時間</div>
                        <div class="col-1">簽八</div>
                        <div class="col-2">
                            <button
                                class="close-btn"
                                type="button"
                                @click="closeLightbox"
                            >
                                完成
                            </button>
                        </div>
                    </div>
                    <div class="list">
                        <div class="row" v-for="item in tableList">
                            <div class="col-3">{{ item.member_name }}</div>
                            <div class="col-2">
                                <template v-if="type === 'waiting_list'">
                                    {{ item.before_date }}
                                </template>
                                <template v-else>
                                    {{ item.end_time }}
                                </template>
                            </div>
                            <div class="col-2">
                                <p 
                                    class="next-status"
                                    :style="{'background-color': item.color}"
                                >
                                    {{ item.reservie_min_time }}
                                </p>
                            </div>
                            <div class="col-2">
                                <Datepicker
                                    class="datepicker-simple"
                                    timePicker 
                                    v-model="item.date"
                                    :clearable="false"
                                    :format="'HH:mm'"
                                    @update:modelValue="handleDate(item.member_id, item.date)"
                                />
                            </div>
                            <div class="col-1">
                                <label class="checkbox-wrap">
                                    <input
                                        type="checkbox"
                                        :false-value="0"
                                        :true-value="1"
                                        v-model="item.sign_eight"
                                        @change="setSignEight(item.member_id, item.sign_eight)"
                                    />
                                    <div class="checkout-show" />
                                </label>
                            </div>
                            <div class="col-2">
                                <b-button
                                    v-if="type === 'waiting_list'"
                                    variant="link"
                                    @click="setWaitListSort(item.member_id, 'up')"
                                >
                                    <div><img src="@/assets/tgd/lightbox/arrow_up.svg" alt="" /></div>
                                </b-button>
                                <b-button
                                    v-if="type === 'waiting_list'"
                                    variant="link"
                                    @click="setWaitListSort(item.member_id, 'down')"
                                >
                                    <div><img src="@/assets/tgd/lightbox/arrow_down.svg" alt="" /></div>
                                </b-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loading-wrap" v-if="loading">
                <div class="spinner-border" role="status" >
                    <span class="visually-hidden">Loading...</span>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import { reactive, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useForm, ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
import { basePost } from '@/js/services/baseService';
import SwalCustom from '@/hooks/common/useSwalCustom';

export default {
    name: 'ChangeMemberListLightbox',
    components: {
        ErrorMessage,
    },
    props: {
        isShow: Boolean,
        type: String
    },
    emits: ['update:isShow'],
    setup(props, { emit }) {
        const isShow = computed(() => props.isShow);
        const { state } = useStore();
        const storeId = computed(() => state.auth.store_id).value;

        const memberWorkList = reactive({
            data: {}
        });

        const loading = ref(true);
        // 取得芳療師服務狀況資訊
        const getMemberWorkStatus = async () => {
            try {
                loading.value = true
                const res = await basePost('/api_room/member_condition', {
                    store_id: storeId,
                });
                const { status, data } = res;

                if (status === 200 && data.res_code === 1) {
                    const { res_data } = data;
                    memberWorkList.data = res_data;
                }
                loading.value = false
            } catch (err) {
                console.error(err);
            }
        };

        const serviceList = computed(()=> {
            return memberWorkList.data.service_list &&
            memberWorkList.data.service_list.map(item =>{
                const time = item.scheduled_end_time.split(':');
                item.date = {
                    hours: typeof time[0] === 'string' ? time[0].trim() : time[0],
                    minutes: typeof time[1] === 'string' ? time[1].trim() : time[1],
                };
                return item
            })
        })

        const waitList = computed(()=> {
            return memberWorkList.data.waiting_list &&
            memberWorkList.data.waiting_list.map(item =>{
                const time = item.end_time.split(':');
                item.date = {
                    hours: typeof time[0] === 'string' ? time[0].trim() : time[0],
                    minutes: typeof time[1] === 'string' ? time[1].trim() : time[1],
                };
                return item
            })
        })
        const tableList = computed(()=> {
            return props.type === 'service_list' ? serviceList.value : waitList.value;
        })

        // 更換排序順序
        const setWaitListSort = async (id, type) => {
            try {
                const res = await basePost('/api_room/update_member_sort', {
                    store_id: storeId,
                    member_id: id,
                    type: type
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    getMemberWorkStatus();
                }
            } catch (err) {
                console.error(err);
            }
        };


        const setSignEight = async (id, sign_eight) => {
            try {
                const res = await basePost('/api_room/update_member_sign_eight', {
                    store_id: storeId,
                    member_id: id,
                    sign_eight
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    getMemberWorkStatus();
                }
            } catch (err) {
                console.error(err);
            }
        };

        function closeLightbox() {
            emit('update:isShow', false);
        }

        watch([isShow], (arr) => {
            if (isShow.value) {
                getMemberWorkStatus();
            }
        });

        const handleDate = async (id, val) => {
            try {
                const res = await basePost('/api_room/update_member_overtime', {
                    store_id: storeId,
                    member_id: id,
                    overtime: `${val.hours}:${val.minutes}`
                });
                const { status, data } = res;
                if (status === 200 && data.res_code === 1) {
                    getMemberWorkStatus();
                }
            } catch (err) {
                console.error(err);
            }
        }

        return {
            closeLightbox,
            setWaitListSort,
            setSignEight,
            handleDate,
            loading,
            tableList
        };
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "~@/css/mixins";
@import "~@/css/grid";
.wait-list-lightbox {
    width: 100%;
    max-width: 700px;
    border-radius: 10px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    box-shadow: 0 8px 21px 0 rgba(0, 0, 0, 0.51);
    background-color: #fff;
}
.lightbox-body {
    padding: 20px 0;
    border-radius: 10px;
    background: linear-gradient(180deg,#ececec,#f9f9f9 35%,#fff) 0 0 no-repeat;
    
    .content {
        margin: 0 auto;
    }
}
.table-list {
    .header {
        padding-left: 20px;
        > div {
            padding: 10px 5px;
            @include flex(center, center);
            font-weight: bold;
            &:first-child {
                justify-content: flex-start;
            }
        }
    }
    .list {
        padding-left: 20px;
        max-height: 80vh;
        overflow: hidden;
        overflow-y: auto;
        scrollbar-width: auto;
        scrollbar-color: #C6C6C6 #E9E9E9;
        &::-webkit-scrollbar {
            width: 4px;
            height: 4px;
        }
        &:-webkit-scrollbar-button {
            height: 200px; 
        }

        &::-webkit-scrollbar-track {
            background: #E9E9E9;
            max-height: 200px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #C6C6C6;
            border-radius: 2px;
            border: 0;
        }
        .row {
            & > div {
                padding: 10px 5px;
                @include flex(center, center);
                &:first-child {
                    justify-content: flex-start;
                }
            }
            &:nth-child(2n + 1){
                background: rgba(214, 214, 214, 0.2);
            }
        }
        .btn {
            margin: 0 5px;
            color: #d5a240;
            img{
                width: 12px;
            }
        }
        .next-status {
            padding: 2px 5px;
            border-radius: 25px;
        }
    }
    .close-btn {
        color: #fff;
        background: transparent linear-gradient(180deg, #ebc496 0%, #a4641a 36%, #b57831 88%, #a4641a 100%) 0% 0% no-repeat padding-box;
        border: 0;
        border-radius: 11px;
        padding: 5px 8px;
        margin-left: 10px;
    }
}


.checkbox-wrap {
  position: relative;
    input:checked + .checkout-show {
        background: #a4641a;
    }

    .checkout-show {
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid #d8d8d8;
        background: white;
    }

    .checkout-show:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 4px;
        width: 5px;
        height: 8px;
        border: solid white;
        border-width: 0 2px 2px 0;
        transform: rotate(45deg);
    }
}

.datepicker-simple {
    :deep(.dp__input) {
        background: transparent;
        border-radius: 0 !important;
    }
}

.loading-wrap {
    @include absolute(0, 0, 0, 0);
    @include flex(center, center);
    border-radius: 10px;
    background: rgba(255,255,255,.4);
}

</style>
